<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">No. SPP</label>
        <vs-input class="w-full" :value="initData.header.no_spk" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Proyek</label>
        <vs-input class="w-full" :value="initData.header.nama_proyek" readonly/>
      </div>
      <div class="vx-col sm:w-2/12 w-full">
        <label class="ml-1 text-xs">Jenis SPP</label>
        <vs-input class="w-full" :value="initData.header.jenis_spk" readonly/>
      </div>
      <div class="vx-col sm:w-2/12 w-full">
        <label class="ml-1 text-xs">Jenis Kontrak</label>
        <vs-input class="w-full" :value="initData.header.jenis_kontrak" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Rekanan</label>
        <vs-input class="w-full" :value="initData.header.nama_rekanan" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Nilai Total Kontrak</label>
        <v-money class="w-full" :value="initData.header.nilai_total" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Status SPP</label>
        <vs-input class="w-full" :value="initData.header.status_spk" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl SPP</label>
        <vs-input class="w-full" :value="initData.header.tgl_spk" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl. Awal Kontrak</label>
        <vs-input class="w-full" :value="initData.header.tgl_awal" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl. Akhir Kontrak</label>
        <vs-input class="w-full" :value="initData.header.tgl_akhir" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Masa (Hari)</label>
        <vs-input class="w-full" :value="initData.header.masa" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Jenis Kontrak</label>
        <vs-input class="w-full" :value="initData.header.jenis_kontrak" readonly/>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Jenis Pekerjaan</label>
        <vs-input class="w-full" :value="initData.header.jenis_pekerjaan" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/1 w-full">
        <label class="ml-1 text-xs">Lingkup Pekerjaan</label>
        <vs-input class="w-full" :value="initData.header.lingkup_pekerjaan" readonly/>
      </div>
    </div>
    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full flex space-x-2">
        <vs-button @click="prev" type="border" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import VMoney from '@/views/components/v-money/VMoney'
export default {
  name: 'TabDetailSpp',
  props: {
    initData: { default: null, type: Object }
  },
  components: {
    VMoney
  },
  methods: {
    prev () {
      const currentActiveTab = this.$store.state.approvals.stkApproval.activeTab
      this.$store.commit('approvals/stkApproval/SET_ACTIVE_TAB', currentActiveTab - 1)
    },

    next () {
      const currentActiveTab = this.$store.state.approvals.stkApproval.activeTab
      this.$store.commit('approvals/stkApproval/SET_ACTIVE_TAB', currentActiveTab + 1)
    }
  }
}
</script>
